import {FontContext, fontStyleGenerator} from 'notability-services-common-frontend'

import localFont from 'next/font/local'
import react from 'react'

export const moranga = localFont({
  src: [
    {path: './Moranga/MorangaRegular_normal_normal.woff2', weight: '400', style: 'normal'},
    {path: './Moranga/MorangaMedium_normal_normal.woff2', weight: '500', style: 'normal'},
    {path: './Moranga/MorangaBold_normal_normal.woff2', weight: '700', style: 'normal'}
  ]
})

export const proxima = localFont({
  src: [
    {path: './ProximaSoft/ProximaSoft_normal_normal.woff2', weight: '400', style: 'normal'},
    {path: './ProximaSoft/ProximaSoftMedium_normal_normal.woff2', weight: '500', style: 'normal'},
    {path: './ProximaSoft/ProximaSoftSemiBold_normal_normal.woff2', weight: '600', style: 'normal'},
    {path: './ProximaSoft/ProximaSoftBold_normal_normal.woff2', weight: '700', style: 'normal'}
  ]
})

export const FontContextProvider = ({children}: {children: react.ReactNode}) => {
  return (
    <FontContext.Provider value={fontStyleGenerator(moranga.style.fontFamily, proxima.style.fontFamily)}>
      {children}
    </FontContext.Provider>
  )
}
