import {useEffect, useRef, useState} from 'react'

export interface Size {
  width: number
  height: number
}

export function useWindowSize() {
  const [size, setSize] = useState<Partial<Size>>({width: undefined, height: undefined})

  const didAddListeners = useRef(false)

  useEffect(() => {
    if (didAddListeners.current) {
      return
    }
    didAddListeners.current = true

    function updateSize() {
      const {clientWidth: width, clientHeight: height} = window.document.body
      setSize({width, height})
    }

    window.addEventListener('load', updateSize)
    window.addEventListener('resize', updateSize)
    window.addEventListener('orientationchange', updateSize)
    updateSize() // Safari desktop apparently never posts the load event (?!) so I guess we still need this
    return () => {
      window.removeEventListener('load', updateSize)
      window.removeEventListener('resize', updateSize)
      window.removeEventListener('orientationchange', updateSize)
      didAddListeners.current = false
    }
  }, [])

  useEffect(() => {
    const timeoutID = window.setTimeout(() => {
      setHiddenUrlQueryParams({restoreWindowWidth: size.width})
    }, 200)
    return () => {
      window.clearTimeout(timeoutID)
    }
  }, [size.width])

  return size
}

/**
 * setHiddenUrlQueryParams
 * - sets query params in history state without changing the url visible to the user
 * - this is most useful when you need to preserve some information between navigations, and you need it to be available at SSR-time so that getServerSideProps' context.params contains the value.
 * - if you can wait until client-side render time, it's better to use React context
 **/
function setHiddenUrlQueryParams(p: {[key: string]: number | string | undefined}) {
  const {state} = window.history
  const splitUrl = (state.url as string).split('?')
  const path = splitUrl[0] ?? ''
  let queryStr = splitUrl[1] ?? ''
  const paramStrs = queryStr.split('&')

  const paramKeyValues: {[key: string]: number | string} = {}
  const paramOrder: {[key: number]: string} = {}
  paramStrs.forEach((str, idx) => {
    const [k = '', v = ''] = str.split('=')
    paramKeyValues[k] = v
    paramOrder[idx] = k
  })

  Object.keys(p).forEach((k) => {
    if (paramKeyValues[k] === undefined) {
      paramOrder[Object.keys(paramKeyValues).length] = k
    }
    paramKeyValues[k] = p[k]!
  })

  queryStr = ''

  const lastIdx = Object.keys(paramKeyValues).length - 1
  for (let i = 0; i <= lastIdx; i++) {
    const k = paramOrder[i]
    if (!k) {
      continue
    }
    const v = paramKeyValues[k]
    if (!v) {
      continue
    }
    queryStr = `${queryStr}${k}=${v}${i === lastIdx ? '' : '&'}`
  }

  window.history.replaceState(
    {
      ...state,
      url: `${path}${queryStr.length === 0 ? '' : '?'}${queryStr}`
    },
    '',
    window.history.state.as
  )
}

export function replaceUrl(url: string) {
  window.history.replaceState(window.history.state, '', url)
}
