import * as React from 'react'

import {useWindowSize} from '../util/useWindowSize'

export type WindowStore = {
  windowWidth?: number
}

export const windowContext = React.createContext({} as WindowStore)

export function WindowContextProvider({children}: {children: React.ReactNode}) {
  const windowSize = useWindowSize()
  const windowWidth = windowSize.width

  const value = React.useMemo(() => ({windowWidth} as WindowStore), [windowWidth])

  return React.createElement(windowContext.Provider, {value}, children)
}
