import {forceLogout} from '../util/clientsideUtils'
import {isErrorForClient} from 'notability-services-common-universal'

/** NOTE: the value of ScreennameKey is user_id but actually refers to the user's screenname due to legacy code. */
const ScreennameKey = 'user_id'
const LoggedInKey = 'logged_in'
const LOGIN_ANALYTICS_KEY = 'login_analytics'

export function assertIsError(error: unknown): asserts error is Error {
  if (!(error instanceof Error)) {
    throw error
  }
}

/// Returns true if the app level handler handles the error
export async function appLevelErrorHandler(error: any) {
  if (typeof window !== 'undefined' && isErrorForClient(error) && error.forceLogOut) {
    await forceLogout()
    window.localStorage.removeItem(LoggedInKey)
    window.localStorage.removeItem(ScreennameKey)
    window.localStorage.removeItem(LOGIN_ANALYTICS_KEY)
    window.location.reload()
    return true
  }
  return false
}
