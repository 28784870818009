import '../styles/login-service.scss'

import {
  LightThemeContextProvider,
  CombinedContextProvider,
  initFathom,
  logFathomView,
  logFirstVisit,
  MixpanelContextProvider
} from 'notability-services-common-frontend'
import {FontContextProvider, moranga, proxima} from '../fonts'
import React, {ReactNode} from 'react'

import {ApolloContextProvider} from '../util/ApolloContext'
import {AppProps} from 'next/app'
import ErrorBoundary from '../components/ErrorBoundary'
import {GoogleOAuthProvider} from '../util/GoogleOAuth'
import {WindowContextProvider} from '../util/windowContext'
import {appWithTranslation} from 'next-i18next'
import {config} from '../config'
import {nonFatalAssert} from 'notability-services-common-universal'
import {useRouter} from 'next/router'

const LoginAnalyticsContextProvider = ({children}: {children: ReactNode}) => {
  return (
    <MixpanelContextProvider isSwiftApp={false} mixpanelAppId={config.MIXPANEL_APP_ID} isLocalDev={config.LOCAL_DEV}>
      {children}
    </MixpanelContextProvider>
  )
}

const appContextProviders = [
  ApolloContextProvider,
  LoginAnalyticsContextProvider,
  LightThemeContextProvider,
  WindowContextProvider,
  FontContextProvider,
  GoogleOAuthProvider
]

function MyApp({Component, pageProps}: AppProps) {
  const router = useRouter()

  const didFirstRender = React.useRef(false)

  React.useEffect(() => {
    if (!didFirstRender.current) {
      initFathom()
      logFirstVisit('Login')
    }
    didFirstRender.current = true
  }, [])

  React.useEffect(() => {
    logFathomView(router.pathname)
  }, [router.pathname])

  // This check ensures that all pages are wrapped with GalleryContext information.
  // TODO: this check fails on static pages that are not manually excluded here :/
  if (config.LOCAL_DEV && Component.name !== 'FourOhFour' && Component.name !== 'ErrorPage') {
    nonFatalAssert(
      (Component as any)['__isComponentWithGalleryContext'],
      `A Page was not wrapped by ComponentWithGalleryContext: ${Component}`
    )
  }

  return (
    <ErrorBoundary>
      <style
        // eslint-disable-next-line react/no-unknown-property
        jsx={true}
        // eslint-disable-next-line react/no-unknown-property
        global={true}
      >
        {`
          :root {
            --font-moranga: ${moranga.style.fontFamily};
            --font-proxima: ${proxima.style.fontFamily};
          }
        `}
      </style>
      <CombinedContextProvider contextProviders={appContextProviders}>
        <Component {...pageProps} key={router.asPath} />
      </CombinedContextProvider>
    </ErrorBoundary>
  )
}

export default appWithTranslation(MyApp)
