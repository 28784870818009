import assert from 'assert'

export interface Config {
  BASE_PATH: string
  LOCAL_DEV: boolean
  API_SERVICE_URL: string
  GALLERY_SERVICE_URL: string
  MARKETING_SERVICE_URL: string
  ORG_SERVICE_URL: string
  LOGIN_SERVICE_URL: string
  SUBSCRIBE_URL: string
  APP_URL: string
  APPLE_CLIENT_ID: string
  APPLE_SCOPES: string
  APPLE_REDIRECT_URI: string
  DB_HOST: string
  DB_NAME?: string
  DB_PASSWORD: string
  NOTABILITY_JWT_SECRET: string
  REDIS_HOST: string
  REDIS_PORT: number
  MIXPANEL_APP_ID: string
  NEXT_PUBLIC_GOOGLE_OAUTH_WEB_CLIENT_ID: string
  STRIPE_PUBLISHABLE_KEY: string
}

export const isServer = typeof window === 'undefined'

// These config values are only available on the server during SSR:
if (isServer) {
  assert(typeof process.env.DB_HOST !== 'undefined', 'DB_HOST is not defined')
  assert(typeof process.env.DB_PASSWORD !== 'undefined', 'DB_PASSWORD is not defined')
  assert(typeof process.env.NB_REDIS_HOST !== 'undefined', 'NB_REDIS_HOST is not defined')
  assert(typeof process.env.NB_REDIS_PORT !== 'undefined', 'NB_REDIS_PORT is not defined')
  // TODO: https://github.com/Ginger-Labs/Notability/issues/30050
  // assert(typeof process.env.NOTABILITY_JWT_SECRET !== 'undefined', 'NOTABILITY_JWT_SECRET is not defined')
} else {
  // TODO: https://github.com/Ginger-Labs/Notability/issues/30050
  // assert(
  //   typeof process.env.NOTABILITY_JWT_SECRET === 'undefined',
  //   'NOTABILITY_JWT_SECRET must not be defined for frontend'
  // )
}

// TODO: re-enable this when next.js fixes this bug
// https://github.com/vercel/next.js/issues/64832
// https://github.com/Ginger-Labs/Notability/issues/30050
// assert(typeof process.env.NEXT_PUBLIC_BASE_PATH !== 'undefined', 'BASE_PATH is not defined')
assert(typeof process.env.MIXPANEL_APP_ID !== 'undefined', `MIXPANEL_APP_ID is not defined.`)
assert(typeof process.env.NEXT_PUBLIC_API_SERVICE_URL !== 'undefined', 'NEXT_PUBLIC_API_SERVICE_URL is not defined')
assert(
  typeof process.env.NEXT_PUBLIC_GALLERY_FRONTEND_SERVICE_URL !== 'undefined',
  'NEXT_PUBLIC_GALLERY_FRONTEND_SERVICE_URL is not defined'
)
assert(
  typeof process.env.NEXT_PUBLIC_LOGIN_FRONTEND_SERVICE_URL !== 'undefined',
  'NEXT_PUBLIC_LOGIN_FRONTEND_SERVICE_URL is not defined'
)
assert(
  typeof process.env.NEXT_PUBLIC_MARKETING_FRONTEND_SERVICE_URL !== 'undefined',
  'NEXT_PUBLIC_MARKETING_FRONTEND_SERVICE_URL is not defined'
)
assert(typeof process.env.NEXT_PUBLIC_WEB_SUBSCRIBE_URL !== 'undefined', 'NEXT_PUBLIC_WEB_SUBSCRIBE_URL is not defined')
assert(
  typeof process.env.NEXT_PUBLIC_ORG_FRONTEND_SERVICE_URL !== 'undefined',
  'NEXT_PUBLIC_ORG_FRONTEND_SERVICE_URL is not defined'
)
assert(typeof process.env.APP_URL !== 'undefined', 'APP_URL is not defined')
assert(typeof process.env.NEXT_PUBLIC_APPLE_CLIENT_ID !== 'undefined', 'APPLE_CLIENT_ID is not defined')
assert(typeof process.env.NEXT_PUBLIC_APPLE_SCOPES !== 'undefined', 'APPLE_SCOPES is not defined')
assert(
  typeof process.env.NEXT_PUBLIC_APPLE_REDIRECT_URI !== 'undefined',
  'NEXT_PUBLIC_APPLE_REDIRECT_URI is not defined'
)

export const config: Config = {
  DB_HOST: process.env.DB_HOST!,
  DB_NAME: process.env.DB_NAME,
  REDIS_HOST: process.env.NB_REDIS_HOST!,
  REDIS_PORT: Number(process.env.NB_REDIS_PORT),
  DB_PASSWORD: process.env.DB_PASSWORD!,
  BASE_PATH: process.env.NEXT_PUBLIC_BASE_PATH!,
  LOCAL_DEV: process.env.LOCAL_DEV === 'true',
  MIXPANEL_APP_ID: process.env.MIXPANEL_APP_ID!,
  API_SERVICE_URL: process.env.NEXT_PUBLIC_API_SERVICE_URL!,
  GALLERY_SERVICE_URL: process.env.NEXT_PUBLIC_GALLERY_FRONTEND_SERVICE_URL!,
  MARKETING_SERVICE_URL: process.env.NEXT_PUBLIC_MARKETING_FRONTEND_SERVICE_URL!,
  ORG_SERVICE_URL: process.env.NEXT_PUBLIC_ORG_FRONTEND_SERVICE_URL!,
  LOGIN_SERVICE_URL: process.env.NEXT_PUBLIC_LOGIN_FRONTEND_SERVICE_URL!,
  SUBSCRIBE_URL: process.env.NEXT_PUBLIC_WEB_SUBSCRIBE_URL!,
  APP_URL: process.env.APP_URL!,
  APPLE_CLIENT_ID: process.env.NEXT_PUBLIC_APPLE_CLIENT_ID!,
  APPLE_SCOPES: process.env.NEXT_PUBLIC_APPLE_SCOPES,
  APPLE_REDIRECT_URI: process.env.NEXT_PUBLIC_APPLE_REDIRECT_URI!,
  STRIPE_PUBLISHABLE_KEY: process.env.STRIPE_PUBLISHABLE_KEY!,
  NOTABILITY_JWT_SECRET: process.env.NOTABILITY_JWT_SECRET!,
  NEXT_PUBLIC_GOOGLE_OAUTH_WEB_CLIENT_ID: process.env.NEXT_PUBLIC_GOOGLE_OAUTH_WEB_CLIENT_ID!
}

export const addingBasePath = (url: string) => `${config.BASE_PATH}${url}`
