import {
  defineStyles,
  themeIsDark,
  useGLTranslation,
  useTheme,
  useThemedStyles
} from 'notability-services-common-frontend'

import {PageMetadata} from './PageMetadata'
import {addingBasePath} from '../config'

const styles = defineStyles((theme, font) => ({
  header: {
    marginTop: '4px',
    ...font.largeTitle
  },

  text: {
    textAlign: 'center',
    maxWidth: '857px',
    fontSize: '18px',
    marginTop: '12px',
    color: theme.core.f2
  },

  image: {
    margin: '14px 0',
    width: '530px',
    maxWidth: '70vw'
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px'
  }
}))

export interface ErrorPageProps {
  pageTitle: string
  pageHeader: string
  pageText: string
  isNotFoundError?: boolean
}

export function ErrorMessage({pageTitle, pageHeader, pageText, isNotFoundError}: ErrorPageProps) {
  const {t} = useGLTranslation()
  const s = useThemedStyles(styles)
  const {theme} = useTheme()
  const imagePath = isNotFoundError
    ? themeIsDark(theme.name)
      ? '/no-results-dark.png'
      : '/no-results-light.png'
    : themeIsDark(theme.name)
    ? '/error-dark.svg'
    : '/error.svg'

  return (
    <div style={s.content}>
      <PageMetadata title={t('pageTitleSub', {pageTitle})} />
      <div style={s.header}>{pageHeader}</div>
      <div style={s.text}>{pageText}</div>
      <img style={s.image} alt={t('pageTitleError')} src={addingBasePath(imagePath)} />
    </div>
  )
}
