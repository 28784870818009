import * as React from 'react'

import Head from 'next/head'
import {useRouter} from 'next/router'
interface PageMetadataProps {
  /** Title of the page as it would appear in the browser tab or search engine. */
  title?: string

  /** Image used to preview the content of the page. */
  previewImage?: string

  /** Description of the page as it would appear in a site preview or a search engine. */
  description?: string
}

export function PageMetadata({title, previewImage, description}: PageMetadataProps) {
  const router = useRouter()

  return (
    <Head>
      <link key="icon" rel="icon" href="/favicon.ico" />
      <meta key="viewport" property="viewport" content="width=device-width, initial-scale=1.0 viewport-fit=auto" />
      <meta key="twitter:card" property="twitter:card" content="summary_large_image" />
      <meta name="apple-itunes-app" content={`app-id=360593530, app-argument=notability://gallery${router.asPath}`} />
      {title && (
        <>
          <title key="pageTitle">{title}</title>
          <meta key="og:title" property="og:title" content={title} />
        </>
      )}
      {previewImage && (
        <>
          <meta key="og:image" property="og:image" content={previewImage} />
          <meta key="twitter:image" property="twitter:image" content={previewImage} />
        </>
      )}
      {description && <meta key="og:description" property="og:description" content={description} />}
      {<meta key="og:type" property="og:type" content={'website'} />}
    </Head>
  )
}
