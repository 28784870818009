import {GoogleOAuthProvider as GoogleOAuthProviderComponent} from '@react-oauth/google'
import {ReactNode} from 'react'
import {config} from '../config'

export function GoogleOAuthProvider({children}: {children: ReactNode}) {
  return (
    <GoogleOAuthProviderComponent clientId={config.NEXT_PUBLIC_GOOGLE_OAUTH_WEB_CLIENT_ID}>
      {children}
    </GoogleOAuthProviderComponent>
  )
}
