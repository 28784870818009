import {getPasswordRequirements, getUsernameRequirements} from 'notability-services-common-universal'
import {useGLTranslation, useLocalizedFetchOptions} from 'notability-services-common-frontend'

import {config} from '../config'

export async function forceLogout() {
  await fetch(`${config.API_SERVICE_URL}/logout`, {credentials: 'include', method: 'GET', mode: 'cors'})
}

export function useLogout(onFinish: () => void) {
  const options = useLocalizedFetchOptions()
  return async () => {
    await fetch(`${config.API_SERVICE_URL}/logout`, {...options, credentials: 'include', method: 'GET', mode: 'cors'})
    onFinish()
  }
}

export function usePasswordRequirements() {
  const {t} = useGLTranslation()
  return getPasswordRequirements(t as typeof i18n.__)
}

export function useUsernameRequirements() {
  const {t} = useGLTranslation()
  return getUsernameRequirements(t as typeof i18n.__)
}
