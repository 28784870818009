import {ApolloClient, ApolloProvider, InMemoryCache, createHttpLink, from} from '@apollo/client'
import React, {useMemo} from 'react'

import {appLevelErrorHandler} from './error'
import {config} from '../config'
import {onError} from '@apollo/client/link/error'
import {useRouter} from 'next/router'

function createApolloClientForLocale(locale?: string) {
  const httpLink = createHttpLink({
    uri: config.LOCAL_DEV ? `${config.API_SERVICE_URL}/graphql` : `${config.API_SERVICE_URL}/global`,
    credentials: config.LOCAL_DEV ? 'include' : 'same-origin',
    headers: locale ? {'accept-language': locale} : undefined
  })

  const errorLink = onError(({graphQLErrors}) => {
    if (graphQLErrors && graphQLErrors[0]) {
      appLevelErrorHandler(graphQLErrors[0])
    }
  })
  const combinedLink = from([errorLink, httpLink])

  const client = new ApolloClient({
    link: combinedLink,
    cache: new InMemoryCache()
  })

  return client
}

export function ApolloContextProvider({children}: {children: React.ReactNode}) {
  const router = useRouter()
  const apolloClient = useMemo(() => createApolloClientForLocale(router.locale), [router.locale])

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}
