import * as Sentry from '@sentry/nextjs'
import React, {Component, ErrorInfo, ReactNode} from 'react'

import {ErrorMessage} from './ErrorMessage'
import {useGLTranslation} from 'notability-services-common-frontend'
import {config} from '../config'

export function GenericErrorComponent() {
  const {t} = useGLTranslation()
  return <ErrorMessage pageTitle={t('pageTitleError')} pageHeader={t('errorHeader')} pageText={t('errorText')} />
}

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
  errorMessage?: string
}

// Note: Error boundaries can't be functional components, so we have to get a little old-school here.
// https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  }

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      errorMessage: error.message
    }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureException(error)
    if (config.LOCAL_DEV) {
      console.error('Uncaught client error:', error, errorInfo)
    }
  }

  public render() {
    if (this.state.hasError) {
      return <GenericErrorComponent />
    }

    return this.props.children
  }
}

export default ErrorBoundary
